import { type DriveFile } from '~/utils/DriveAPI';
import { concat } from '~/utils/fast';
import { getId, isAFolder } from '~/utils/utils';

const getAllChildren = (files: DriveFile[], parentId: string): DriveFile[] => {
  const children = files.filter((file) => file.parents?.[0] === parentId);
  return concat(
    children,
    children
      .filter((file) => isAFolder(file))
      .flatMap((file) =>
        getAllChildren(
          // filter out the found children to avoid infinite recursion
          files.filter((o) => !children.some((child) => o.id === child.id)),
          getId(file)
        )
      )
  );
};

export default getAllChildren;
