import { VisibilityOutlined } from '@mui/icons-material';
// import { useEffect } from 'react';
import { Menu, MenuItem } from '@mui/material';
import cx from 'classnames';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { useEffect, useLayoutEffect, useReducer } from 'react';
import { createPortal } from 'react-dom';
import * as buttonStyles from '~/elements/Buttons/Button.css';
import { type DriveFile } from '~/utils/DriveAPI';
import { useKeyPress } from '~/utils/hooks';
import { getId, ls } from '~/utils/utils';
import Tooltip from '../Tooltip';
import * as styles from './SheetSettings.css';

const setIframe = (src: string) => {
  const tables = document.querySelectorAll('[data-table]');
  const iframe = document.querySelector('[data-iframe]');
  if (!tables[0]) return;
  if (!iframe) return;
  if (!(iframe instanceof HTMLIFrameElement)) return;
  const links = document.querySelectorAll('[data-link]');
  tables.forEach((table) => table.classList.add('hide'));
  links.forEach((link) => link.classList.add('hide'));
  iframe.classList.remove('hide');
  iframe.src = src;
};

export const SheetSettings = ({ file, query }: { file: DriveFile; query: string }) => {
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const settingsPopupState = usePopupState({ variant: 'popover', popupId: 'settings' });
  const setView = (view: string) => {
    if (view === '') ls.remove('sheet-view');
    else ls.set('sheet-view', view);
  };

  useKeyPress('v', () => {
    settingsPopupState.setAnchorEl(document.querySelector(query));
    settingsPopupState.open();
  });

  useLayoutEffect(() => {
    const view = ls.get<string>('sheet-view');
    if (!view) return;
    const id = getId(file);
    setIframe(`https://docs.google.com/spreadsheets/d/${id}/${view}`);
  }, [file]);

  useEffect(() => {
    if (!file) return;
    const nodes = Array.from(document.querySelectorAll(query));
    const emptyChildren = nodes.filter((node) => !node.hasChildNodes());
    if (nodes.length && emptyChildren.length) {
      forceUpdate();
    }
  }, [file, query]);

  const nodes = Array.from(document.querySelectorAll(query));
  if (!nodes.length) return null;

  return nodes.map((node) =>
    createPortal(
      <>
        <Tooltip title="View (v)">
          <div
            style={{
              cursor: 'pointer',
              display: 'flex',
              alignItems: 'center',
              marginRight: 4,
            }}
          >
            <VisibilityOutlined
              style={{ fontSize: 30, margin: '0px 2px' }}
              className={cx([styles.button, buttonStyles.buttonCircular])}
              // htmlColor="#6D6D6D"
              htmlColor="inherit"
              {...bindTrigger(settingsPopupState)}
            />
          </div>
        </Tooltip>
        <Menu
          PaperProps={{
            style: {
              marginTop: 0,
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transitionDuration={{
            enter: 200,
            exit: 0,
          }}
          open={settingsPopupState.isOpen}
          onClose={() => settingsPopupState.close()}
          anchorEl={settingsPopupState.anchorEl}
        >
          <MenuItem
            onClick={() => {
              settingsPopupState.close();
              const tables = document.querySelectorAll('[data-table]');
              const iframe = document.querySelector('[data-iframe]');
              if (!tables[0]) return;
              if (!iframe) return;
              if (!(iframe instanceof HTMLIFrameElement)) return;
              tables[0].classList.remove('hide');
              const links = document.querySelectorAll('[data-link]');
              links.forEach((link) => link.classList.remove('hide'));
              iframe.classList.add('hide');
              iframe.src = '';
              setView('');
            }}
          >
            Inline
          </MenuItem>
          <MenuItem
            onClick={() => {
              settingsPopupState.close();
              const id = getId(file);
              setIframe(`https://docs.google.com/spreadsheets/d/${id}/preview`);
              setView('preview');
            }}
          >
            Preview
          </MenuItem>
          <MenuItem
            onClick={() => {
              settingsPopupState.close();
              const id = getId(file);
              setIframe(`https://docs.google.com/spreadsheets/d/${id}/edit?rm=minimal`);
              setView('edit?rm=minimal');
            }}
          >
            Minimal
          </MenuItem>
          <MenuItem
            onClick={() => {
              settingsPopupState.close();
              const id = getId(file);
              setIframe(`https://docs.google.com/spreadsheets/d/${id}/edit?rm=demo`);
              setView('edit?rm=demo');
            }}
          >
            Demo
          </MenuItem>
          <MenuItem
            onClick={() => {
              settingsPopupState.close();
              const id = getId(file);
              setIframe(`https://docs.google.com/spreadsheets/d/${id}/edit?rm=embedded`);
              setView('edit?rm=embedded');
            }}
          >
            Embedded
          </MenuItem>
        </Menu>
      </>,
      node
    )
  );
};
