import Skeleton from '@mui/material/Skeleton';
import { useEffect, useReducer, useRef, type CSSProperties } from 'react';
import { createPortal } from 'react-dom';
import { fadeIn } from '~/app.css';

export const SkeletonMacro = ({ content, style, query }: { content: string; style?: CSSProperties; query: string }) => {
  const [, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const contentRef = useRef<string>(content);
  useEffect(() => {
    if (!content) return;
    const nodes = Array.from(document.querySelectorAll(query));
    const emptyChildren = nodes.filter((node) => !node.hasChildNodes());
    if (nodes.length && emptyChildren.length && contentRef.current !== content) {
      forceUpdate();
    }
    contentRef.current = content;
  }, [content, query]);

  const nodes = Array.from(document.querySelectorAll(query));
  if (!nodes.length) return null;
  return nodes.map((node) =>
    createPortal(
      <div className={fadeIn}>
        <Skeleton
          style={{ position: 'absolute', left: 0, top: 0, ...style }}
          variant="rectangular"
          width="100%"
          height="100%"
        />
      </div>,
      node
    )
  );
};
