import { darkTheme, lightTheme } from '~/theme.css';
import { useTheme } from './utils/hooks';
import { getAuthURL } from './utils/utils';

const redirect = window.location.origin;
const url = getAuthURL(redirect);

function Login() {
  const theme = useTheme();
  return (
    <>
      <div
        className={
          {
            dark: darkTheme,
            light: lightTheme,
          }[theme]
        }
      >
        <a href={url}>Login</a>
      </div>
    </>
  );
}

export default Login;
