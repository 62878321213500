import dedent from 'dedent';
import * as app from '~/app.css';
import * as pageStyles from '~/components/Page/Page.css';
import { debug, DEV, htmlOnly, isBun, LEGACY, mimeTypes } from '~/utils/constants';
import { colorIsDark, getMimeType, reg, round, strip } from '~/utils/utils';
import { type DriveFile } from './DriveAPI';
import { log } from './logger';

export const openInNew = /* html */ `<svg focusable="false" aria-hidden="true" viewBox="0 0 24 24" style="width: 1em;height: 1em; margin: -3px 0 0 1px; vertical-align: middle"><path fill="currentColor" d="M19 19H5V5h7V3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2v-7h-2v7zM14 3v2h3.59l-9.83 9.83 1.41 1.41L19 6.41V10h2V3h-7z"></path></svg>`;

export function decodeHTML(html: string) {
  // HTML entities parser (Bun only)
  // https://stackoverflow.com/a/44195856/1845423
  if (isBun && !import.meta.env?.PROD) {
    const translate_re = /&(nbsp|amp|quot|lt|gt);/g;
    const translate = {
      nbsp: ' ',
      amp: '&',
      quot: '"',
      lt: '<',
      gt: '>',
    } as const;

    return html
      .replace(translate_re, (_, entity: keyof typeof translate) => translate[entity])
      .replace(/&#(\d+);/g, (_, numStr: string) => {
        const num = parseInt(numStr);
        return String.fromCharCode(num);
      });
  }

  const txt = document.createElement('textarea');
  txt.innerHTML = html;
  return txt.value;
}

export const parseWordDoc = (data: string): string => {
  // Handle dark mode text for word docs
  // Remove all colors that start with a number
  return data.replaceAll(/color:#\d\w{2,5};/g, '');
};

export const getIframe = ({
  src,
  style,
  width,
  height,
}: { src: string; style: string; width: string; height: string }) => {
  const isLoom = src.startsWith('https://www.loom.com');
  const setVisibility = `this.className='${isLoom ? app.fadeInDelay : app.fadeIn}'; this.style.visibility='visible'; setTimeout(() => { this.previousElementSibling.remove()}, 600);`;

  // Custom embed for loom
  // Shows the image and play button immediately
  if (isLoom) {
    // https://dev.loom.com/docs/embed-sdk/getting-started
    // https://www.loom.com/v1/oembed?url=https://www.loom.com/share/0281766fa2d04bb788eaf19e65135184
    const thumbnail = src.startsWith('https://www.loom.com')
      ? `https://cdn.loom.com/sessions/thumbnails/${src.split('/').pop()}-00001.jpg`
      : '';

    return strip /* html */`
      <div style="position: relative">
        <div style="position: absolute; ${width ? `width:${width};` : ''} ${height ? `height: ${height};` : ''}">
          <a href="${src.replace('embed', 'share')}" target="_blank" style="width: 100%; height: 100%; position: relative; display: block; text-decoration: none;">
            <img src="${thumbnail}" style="width: 100%; height: 100%" />
            <div style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; margin: auto; background-color: rgba(51, 51, 51, 0.1)"></div>
            <img src="/img/loom-play-button.svg" style="position: absolute; top: 0; right: 0; bottom: 0; left: 0; margin: auto; width: 92px; height: 92px;" />
          </a>
        </div>
        <iframe src="${src}" onLoad="${setVisibility}" style="${style}" width="${width}" height="${height}" frameborder="0" allowfullscreen sandbox="allow-scripts allow-forms allow-presentation allow-same-origin allow-popups"></iframe>
      </div>`;
  }

  return strip /* html */`
    <div style="position: relative">
      <div style="position: absolute; ${width ? `width:${width};` : ''} ${height ? `height: ${height};` : ''}">
        <div data-react="SkeletonLoader"></div>
      </div>
      <iframe src="${src}" onLoad="${setVisibility}" style="${style}" width="${width}" height="${height}" frameborder="0" allowfullscreen sandbox="allow-scripts allow-forms allow-presentation allow-same-origin allow-popups"></iframe>
    </div>`;
};

export const modal =
  isBun || typeof HTMLDialogElement === 'function'
    ? strip /* html */`
  <dialog id="dialog" style="padding: 0; border: 0;" onclick="window.event.target === window.event.currentTarget && this.close()">
    <div style="padding: 1.8rem 1rem .8rem 1rem; border: 1px solid #a9a9a9">
      <img id="dialog-src" />
      <form method="dialog" style="display: flex;justify-content: flex-end;padding-bottom: 0.5rem;position: absolute;right: 1px; top: 1px;">
        <button style="outline: 0; cursor: pointer; color: #696969; border: 0;border-radius: 3px;font-family: 'Lato';width: 20px;height: 20px;margin: 0;padding: 0;">&#x2715</button>
      </form>
    </div>
  </dialog>`
    : '';

export const modalClick = (src: string) =>
  isBun || typeof HTMLDialogElement === 'function'
    ? `onclick="document.getElementById('dialog-src').src='${src}'; document.getElementById('dialog').showModal()"`
    : '';

const whitelist = ['youtube.com', 'loom.com', 'google.com', 'docs.google.com', 'drive.google.com'];

function getDomain(url: string) {
  const hostname = new URL(url).hostname;
  const parts = hostname.split('.');
  return parts.length > 2 ? parts.slice(-2).join('.') : hostname;
}

export const parseExport = ({
  html,
  file,
  files,
  wiki,
}: {
  html: string;
  file?: DriveFile | undefined;
  files?: DriveFile[];
  wiki?: DriveFile | undefined;
}) => {
  if (htmlOnly) {
    log.info(html);
    return { content: html };
  }
  if (debug) {
    log.info(html);
  }

  const mimeType = getMimeType(file);
  if (mimeType === mimeTypes.spreadsheet) {
    return { content: html };
  }

  let result = html
    .replace('<html>', '')
    .replace('</html>', '')
    .replace(/<meta[^>]*>/, '')
    .replace(/(style="[^"]*)color:#000000;?/g, '$1')

    // Remove empty first divs
    .replace(/(<body[^>]*>)<div>(?:<p[^>]*><span[^>]*><\/span><\/p>)+<\/div>/, '$1')

    // Remove padding from first heading
    .replace(/(<body[^>]*><h\d[^>]*padding-top:)\d+/, '$10')

    // Remove style and add class to comments
    .replace(
      /(<div\s)style="border:1px solid black;margin:5px"(><p[^<]*<a href="#cmnt_[^>]*>)/g,
      `$1class="page-comment"$2`
    )
    // Replace Arial
    .replace(/(style="[^"]*)font-family:&quot;Arial&quot;;?/g, '$1')

    .replace(/(<h\d[^>]*>)(.*?)(<\/h\d>)/g, (_, tag: string, content: string, closingTag: string) => {
      // if there are nodes within a heading tag, skip them in the next step
      const output = content.replace(/(<\w[^>]*)/g, '$1 data-skip');
      return `${tag}${output}${closingTag}`;
    })
    // Replace unused, default styles in text
    .replace(/<(span|p|li)[^>]*/g, (str) => {
      if (/data-skip/.test(str)) return str.replace(/ data-skip/g, '');
      return str.replace(
        /(style=")*padding:0|text-align:left|orphans:2|widows:2|text-decoration:none|vertical-align:baseline|font-style:normal|font-size:11pt/g,
        '$1'
      );
    })
    // Remove default font-weight for p > span tags
    .replace(/(<p[^>]*>)(.*?)(<\/p>)/g, (_, tag: string, content: string, closingTag: string) => {
      return `${tag}${content.replace(/(style="[^"]*)font-weight:400;?/g, '$1')}${closingTag}`;
    })

    // Bump up font size 10%
    // Bump up text-indent 10%
    .replace(/<\w[^>]*/g, (str) => {
      return str
        .replace(/(style="[^"]*)font-size:(\d+)pt(;)?/g, (str, style: string, size: string, semi = '') => {
          if (size) {
            return `${style}font-size:${round(Number(size) * 1.1, 1)}pt${semi}`;
          }
          return str;
        })
        .replace(/(style="[^"]*)text-indent:(\d+)pt(;)?/g, (str, style: string, size: string, semi = '') => {
          if (size) {
            return `${style}text-indent:${round(Number(size) * 1.1, 1)}pt${semi}`;
          }
          return str;
        });
    })

    // Bump up td width 10%
    .replace(/<td[^>]*/g, (str) => {
      return str.replace(/(style="[^"]*)width:(\d+)pt(;)?/g, (str, start: string, size: string, semi = '') => {
        if (size) {
          return `${start}width:${Math.round(Number(size) * 1.1)}pt${semi}`;
        }
        return str;
      });
    })

    // Bump up image side by 10%
    .replace(/<span[^>]*style="([^"]*)"[^>]*><img[^>]*style="([^"]*)/g, (str, spanStyle: string, imgStyle: string) => {
      return str
        .replace(
          spanStyle,
          spanStyle.replace(
            /(width|height):\s?([\d.]+)px/g,
            (_, wh: string, size: string) => `${wh}:${Math.round(Number(size) * 1.1)}px`
          )
        )
        .replace(
          imgStyle,
          imgStyle.replace(
            /(width|height):\s?([\d.]+)px/g,
            (_, wh: string, size: string) => `${wh}:${Math.round(Number(size) * 1.1)}px`
          )
        );
    })

    // Add padding to bottom of headings
    .replace(/<h\d[^>]*>/g, (str) => {
      return str.replace(/(style="[^"]*)padding-bottom:(\d+)pt(;)?/g, (str, start: string, size: string, semi = '') => {
        return size ? `${start}padding-bottom:${Number(size) + 2}pt${semi}` : str;
      });
    })

    // Replace default link color
    .replace(/<span[^>]*><a[^>]*>/g, (str) => {
      if (/color:#1155cc/.test(str)) {
        return str.replace(/(style="[^"]*)color:#1155cc/g, '$1').replace(/(style="[^"]*)color:inherit/g, '$1');
      }
      return str;
    })

    // Detect dark text
    // eslint-disable-next-line
    .replace(/ style="[^"]*color:(#\w+)[^"]*"/g, (str, color: string) => {
      const isDark = colorIsDark(color);
      return str + (isDark ? ' data-is-dark="true"' : '');
    })
    // Detect light background
    // eslint-disable-next-line
    .replace(/ style="[^"]*background-color:(#\w+)[^"]*"/g, (str, color: string) => {
      const isLight = !colorIsDark(color);
      return str + (isLight ? ' data-is-light-bg="true"' : '');
    })

    // Inject heading links
    .replace(/(<h\d[^>]*>)(.*?)(<\/h\d>)/g, (_, heading: string, content: string, closingTag: string) => {
      const id = heading.match(/id="([^"]*)"/)?.[1];
      const indent = heading.match(/text-indent:(-\d+)pt;/)?.[1];
      const value = -Number(indent ?? 0);
      const left = value > 0 ? value + 6 : value;
      const hash = id
        ? /*html*/ `<a href="#${id}" style="left: ${left - 32}px" class="${pageStyles.pageHashLink}" aria-hidden="true">#</a>`
        : '';

      // Do not create hash links for empty titles
      // http://localhost:3000/app/page/1NqCSiMuEfPfaHTumR_rX6J8zo7ygjx8q/1ylZJQs1Iic8WxU6bmC6bJj8yRVJ7WOSyOEEelGG3v48?preview
      // http://localhost:3000/app/page/1NqCSiMuEfPfaHTumR_rX6J8zo7ygjx8q/1O5f0Pu21kWaZzCewQiVuW-JquTu4L3nsbcATmWI1h_k?preview
      const text = content.replace(reg.tags, '');
      if (!text) return _;

      const output = content.includes('<span')
        ? content.replace(/<span[^>]*>/, (span) => {
            if (span === '<span>') return `<span style="position:relative;">${hash}`;
            const output = span.replace(/(style="[^"]+)/, '$1;position:relative;');
            return `${output}${hash}`;
          })
        : `${content}${hash}`;

      const headingOutput = heading.includes('class')
        ? heading.replace('class="', `class="${pageStyles.pageHeading} `)
        : heading.replace(/>$/, ` class="${pageStyles.pageHeading}">`);

      return `${headingOutput}${output}${closingTag}`;
    })

    // --- Images ---
    // Replaces display: inline-block from surrounding span with display: flex
    // .replace(/<li((?!<\/li>).)*/g, (a) => {
    //   if (!a.includes('<img')) return a;
    //   return a.replace(/<span[^>]*><img/, (a) => a.replace(/display:\s?inline-block;?/, 'display: flex;'));
    // })

    // Remove empty spans in paragraphs
    .replace(/(<p[^>]*>)<span[^>]*><\/span>/g, '$1')

    // Convert <br> at the end of lists to display block
    // Don't use <div> because a <div> inside a <p> is invalid
    // Testing: http://localhost:3000/app/page/1nBSr8AxTNwiuQbugrjcILqhC3c-3YgKFCBm8iw0yol4
    // http://localhost:3000/app/page/16Gs1rRlLKNUsSEJ3RP9qKiFvIuH1xScT/16xP4snmp7_ITZh9mC10wVP_ReOFEpVd_xQ23Na2JVIY
    .replace(/<br><\/span><\/li>/g, /*html*/ `<span style="display: block"><br></span></span></li>`)

    // Formatting for small and large images
    .replace(/(<span[^>]*>)(<img[^>]*>)/g, (str, span: string, img: string) => {
      const width = span.match(/width:\s?([\d.]+)px/)?.[1];
      if (!width) return str;
      if (Number(width) > 708) {
        return (
          span.replace(/(style="[^"]*)width:\s?[\d.]+px/g, '$1width:auto') + img.replace(/\s/, ' data-size="large" ')
        );
      }
      return span + img.replace(/\s/, ' data-size="small" ');
    })
    // --- End of images ---

    // Fix ul left margin
    .replace(/<ul[^>]*>/g, (ul: string) => ul.replace('margin:0', 'margin:0;margin-left:1.8rem'))

    // Decrease the margin for (ul | ol) > li
    // Checkboxes have half margin
    // bullet points have -18pt margin
    .replace(/<(?:o|u)l.*?<\/(?:o|u)l>/g, (ul: string) => {
      return ul.replace(/<li.*?<\/li>/g, (li: string) => {
        const baseMargin = 36;
        return li.replace(/margin-left:(\d+)pt(;)?/, (_, val: string, semi = '') => {
          const a = Number(val);
          if (li.includes('{{checkbox}}')) {
            // Checkboxes have half margin for first level, then decrease by 32pt
            if (a === baseMargin) return `margin-left:${Math.round(a / 2)}pt;`;
            return `margin-left:${Math.round(a - 32)}pt${semi}`;
          }
          return `margin-left:${Math.round(a - 18)}pt${semi}`;
        });
      });
    })

    // Add position relative to li
    .replace(/<li[^>]*>/g, (li: string) => {
      return li.replace('style="', 'style="position:relative;');
    })

    // Fix bullet point sizing and position
    .replace(/<style type="text\/css">[^<]*<\/style>/, (str) => {
      const css = `
        font-size:.55rem;
        position:absolute;
        left:-1.6rem;
        top: 6px;
      `.replace(/\s+/g, '');
      return (
        str
          .replace(/(li:before\{content:"[●○■]\s*")\}/g, `$1;${css}}`)
          // Replaces all other custom bullet points (❖, ★, ➢, etc)
          // http://localhost:3000/app/page/1NqCSiMuEfPfaHTumR_rX6J8zo7ygjx8q/1O5f0Pu21kWaZzCewQiVuW-JquTu4L3nsbcATmWI1h_k?preview
          .replace(/(li:before\{content:"\S\s*")\}/g, `$1;position:absolute;left:-1.6rem;}`)
      );
    })

    // Fix "-" bullet point sizing and position
    .replace(/<style type="text\/css">[^<]*<\/style>/, (str) => {
      const css = 'position:absolute;left:-1.7rem;';
      return str.replace(/(li:before\{content:"-\s*")\}/g, `$1; ${css}}`);
    })

    // If there is a checkbox in the li, remove left margin
    .replace(/<li.*?<\/li>/g, (li: string) => {
      return li.includes('{{checkbox}}') ? li.replace(/margin-left:18pt;/, 'margin-left:0;') : li;
    })

    // Re-size bullet points
    .replace(/&#9679;/g, /*html*/ `<span style="font-size:.55rem;vertical-align:middle;">&#9679;</span>`)

    // Adjust number bullet point spacing
    // http://localhost:3000/app/page/1KVjpqsTJ1un79FChj4QE2coy4FZi0q5M/1uT4LRiE7lx9wnItssk4p27QG_0jAjFb6HM7WQ67vQs0?preview
    .replace(/<style type="text\/css">[^<]*<\/style>/, (str) => {
      const css = `letter-spacing:.05rem;`;
      return str.replace(/(li:before\{content:"" counter\([^}]*)/g, `$1;${css}`);
    })
    // .replace(/<ol.*?<\/ol>/g, (ol: string) => {
    //   return ol.replace(/<li.*?<\/li>/g, (li: string) => {
    //     return li.replace(/margin-left:36pt/g, 'margin-left:18pt');
    //   });
    // })

    // images in lists have a small margin-top
    .replace(/(<li.*?)(<span[^>]*>)(<img)/g, (_, li: string, span: string, img: string) => {
      return li + span.replace('margin: 0.00px 0.00px;', 'margin: 2px 0px 0px 0px;') + img;
    })

    // tables do not word break, causing unexpected overflow of long words
    .replace(/<table[^>]*>/g, (table: string) => table.replace('style="', 'style="word-break:break-word;'))

    // Add correct spacing for empty p tags
    .replace(/<p[^>]*><\/p>/g, (p: string) => {
      // 1.30434783
      return p.replace(/height:(\d+)pt;/, (_, a: string) => {
        return `height:${Math.round(Number(a) * 1.45)}pt;`;
      });
    })

    // Fixes line-height
    .replace(
      // eslint-disable-next-line
      /<(\w+)[^>]*line-height:(\d+\.?\d*|\.\d+)/g,
      (str, tag: string, a: string) => {
        return tag === 'p'
          ? str.replace(/line-height:(\d+\.?\d*|\.\d+)/, `line-height:${(Number(a) * 1.2).toFixed(1)}`)
          : str.replace(/line-height:(\d+\.?\d*|\.\d+)/, `line-height:${(Number(a) * 1.18).toFixed(2)}`);
      }
    )

    // Fixes line height at end of list
    // See: http://localhost:3000/app/page/1DgH8f84SGT5FS3A6svH_-fTdNVD1LvTb/1otb4omczEP8toYUSyNjzQl14OdPH0yCiezXMH5tHeZE
    .replace(/<li((?!<\/li>).)*<\/li><\/ul>/, (str) => {
      const style = str.match(/<li\sstyle="([^"]+)"/)?.[1];
      const paddingBottom = style?.match(/padding-bottom:(\d+pt);?/)?.[1];
      if (paddingBottom) {
        return str
          .replace(/margin-bottom:\d;/, '')
          .replace(/padding-bottom:\d+pt;?/, '')
          .replace(/(<li\sstyle=")/, `$1margin-bottom:${paddingBottom};`);
      }
      return str;
    })
    // Remove all other padding
    .replace(/(<li\sstyle="[^>]*)padding-top:\d+pt;?/g, '$1')
    .replace(/(<li\sstyle="[^>]*)padding-bottom:\d+pt;?/g, '$1')

    // Remove -ve margin-left from tables (pageless)
    .replace(/(<table style="[^"]+)(margin-left:-[\d.]+pt;?)/g, '$1')

    // Remove empty style attributes
    .replace(/\s?style=";*"/g, '')
    // --- End of style changes ---

    // Add no-referrer for images in development
    // to fix rate limiting error
    // https://stackoverflow.com/a/41884494/1845423
    .replace(/<img/g, (str) => {
      if (DEV) return str.replace(/<img/g, `<img referrerpolicy="no-referrer"`);
      return str;
    })

    // Video
    .replace(/&lt;video[^/]*src((?!video).)*\/video&gt;/g, (a) => {
      const html = decodeHTML(a);
      const src = html.match(/src="([^"]*)"/)?.[1];
      const width = html.match(/width="([^"]*)"/)?.[1] ?? '100%';
      const height = html.match(/height="([^"]*)"/)?.[1] ?? '100%';
      if (!src) return a;

      const style = html.match(/video[^>]*style="([^"]*)"/)?.[1] ?? '';

      return dedent /* html */`
        <video controls style="${style}" width="${width}" height="${height}">
          <source src="${src}">
        </video>
      `.replace(/\n\s*/g, '');
    })

    // Loom embed <div><iframe></iframe></div>
    // We use ((?!&gt).)* instead of .* to avoid injections
    // http://localhost:3000/app/page/17715VfJmL3JHx90XD8KsuZH9yz4dGP55/12hXQvzYvDhdUNnxeyKNXV2cCWZXtmylwRvz8YwfBISQ
    .replace(/(&lt;div((?!&gt).)*&gt;)&lt;iframe((?!&gt).)*&gt;&lt;\/iframe&gt;(&lt;\/div&gt;)/g, (a) => {
      const html = decodeHTML(a);
      const src = html.match(/src="([^"]*)"/)?.[1];
      const width = html.match(/width="([^"]*)"/)?.[1] ?? '100%';
      const height = html.match(/height="([^"]*)"/)?.[1] ?? '100%';
      if (!src) return a;

      const containerStyle = html.match(/div[^>]*style="([^"]*)"/)?.[1] ?? '';
      const s = html.match(/iframe[^>]*style="([^"]*)"/)?.[1] ?? '';
      const style = s ? `${s.trimEnd().endsWith(';') ? s : s + ';'} visibility: hidden` : 'visibility: hidden';

      const delay = src.startsWith('https://www.loom.com') ? app.fadeInDelay : app.fadeIn;
      const setVisibility = `this.className='${delay}'; this.style.visibility='visible'; setTimeout(() => { this.previousElementSibling.remove()}, 600);`;

      return strip /* html */`
        <div style="position: relative">
          <div style="${containerStyle ?? ''}">
            <div data-react="SkeletonLoader"></div>
            <iframe src="${src}" onLoad="${setVisibility}" style="${style}" width="${width}" height="${height}" frameborder="0" allowfullscreen sandbox="allow-scripts allow-forms allow-presentation allow-same-origin allow-popups"></iframe>
          </div>
        </div>`;
    })

    // iframe embed that has an <a> or <span> tag in the in src
    // https://mail.google.com/mail/u/0/#label/Support%2FEmail/FMfcgzGsmNPzcgstHnFlgbgNjzkWWDWT
    .replace(/(&lt;iframe((?!&gt).)*?src=&quot;)<\/span>[\s\S]*?&lt;\/iframe&gt;/g, (a) => {
      // https://regex101.com/r/9NvTyu/1
      const hrefSrc = (a.match(/&lt;iframe(?:(?!&gt).)*?src=&quot;[\s\S]*?a href=[^>]*>([^<]*)/) || [])[1];

      // https://regex101.com/r/eVTbwW/1
      const altSrc = (a.match(/&lt;iframe(?:(?!&gt).)*?src=&quot;[\s\S]*?(https:\/\/[^*<]*)/) || [])[1];

      const src = (hrefSrc || altSrc) ?? '';

      const html = decodeHTML(a).replace('<iframe ', '');
      const attrs = html.replace(/<[^>]*>/g, '');

      const w = attrs.match(/width="([^"]*)"/)?.[1];
      const h = attrs.match(/height="([^"]*)"/)?.[1];
      const width = w ? `${w}px` : '100%';
      const height = h ? `${h}px` : '100%';
      const s = attrs.match(/style="([^"]*)"/)?.[1];
      const style = s ? `${s.trimEnd().endsWith(';') ? s : s + ';'} visibility: hidden` : 'visibility: hidden';

      const iframe = getIframe({
        src,
        width,
        height,
        style,
      });

      return iframe;
    })

    // General embed <iframe></iframe>
    // General embed <iframe>Loading...</iframe>
    .replace(/&lt;iframe((?!&gt).)*&gt;[^/]*&lt;\/iframe&gt;/g, (a) => {
      const html = decodeHTML(a);
      const src = html.match(/src="([^"]*)"/)?.[1];
      const w = html.match(/width="([^"]*)"/)?.[1];
      const h = html.match(/height="([^"]*)"/)?.[1];
      const width = w ? `${w}px` : '100%';
      const height = h ? `${h}px` : '100%';
      if (!src) return a;
      const domain = getDomain(src);
      if (!whitelist.includes(domain)) {
        TrackJS.console.log('domain', domain);
        TrackJS.track('iframe not supported for domain');
        return a;
      }

      const s = html.match(/iframe[^>]*style="([^"]*)"/)?.[1] ?? '';
      const style = s ? `${s.trimEnd().endsWith(';') ? s : s + ';'} visibility: hidden` : 'visibility: hidden';

      const iframe = getIframe({
        src,
        width,
        height,
        style,
      });

      return iframe;
    })

    // Video embed: <video><source src="" type="video/mp4"></source></video>
    .replace(
      /(&lt;div((?!&gt).)*&gt;)?&lt;video((?!&gt).)*&gt;&lt;source((?!&gt).)*&gt;&lt;\/source&gt;&lt;\/video&gt;(&lt;\/div&gt;)?/g,
      (a) => {
        return decodeHTML(a);
      }
    )

    // Dropbox image
    // https://regex101.com/r/39156H/1
    .replace(
      /(&lt;img((?!&gt).)*src=&quot;((?!&gt).)*(https:\/\/www\.dropbox\.com\/s\/.*?)&quot;((?!&gt).)*\/&gt;)/g,
      (a) => {
        return a.replace('?dl=0', '?raw=1');
      }
    )
    // Image embedding
    .replace(/(&lt;img((?!&gt).)*src=&quot;((?!&gt).)*&quot;((?!&gt).)*\/&gt;)/g, (a) => {
      const html = decodeHTML(a);
      // Add max-width: 100% to images
      const style = html.match(/style="([^"]*)"/)?.[1] ?? '';
      if (!style) {
        return html.replace('/>', 'style="max-width: 100%" />');
      }
      return html.replace(style, `${style};max-width: 100%`);
    })

    // Remove &amp and onwards after ?q=
    .replace(/(href="https:\/\/www\.google\.com\/url\?q=[^"&]*)&amp[^"]*/g, '$1')

    // Handle https://www.google.com/url?q= links
    .replace(/(href="https:\/\/www\.google\.com\/url\?q=[^>]*>[^<]*)(<\/a>)/g, (a, b: string, c: string) => {
      if (a.includes('?external') || a.includes('%26external')) {
        return a.replace(/(href=")https:\/\/www.google.com\/url\?q=/g, '$1');
      }

      const pageLink = b
        // Fix links
        .replace(/(href=")https:\/\/www.google.com\/url\?q=/g, '$1')
        .replace(/href="https:\/\/drive\.google\.com\/file\/d\//g, 'href="/app/page/')
        .replace(/href="https:\/\/drive\.google\.com\/open\?id%3D/g, 'href="/app/page/')
        // Replace /edit
        .replace(/(href="https:\/\/docs\.google\.com[^"]+)\/edit[^"]*/g, '$1')
        // Replace docs.google.com
        .replace(/href="https:\/\/docs\.google\.com\/[^"]+\/d\//g, 'href="/app/page/');

      // Separate URL for image
      // const documentURL = b.match(/href="([^"]*)"/)?.[1]?.replace('https://www.google.com/url?q=', '');
      // return pageLink + c + (documentURL ? /* html */ `<a target="_blank" href="${documentURL}">${img}</a>` : img);

      // Document icon
      // if (b.includes(editURLs[mimeTypes.document].replace(/\/d\/$/, ''))) {
      //   return pageLink + img + c;
      // }

      const headingId = b.match(/(%23heading%3D)([\w.]+)/)?.[2];
      const link = headingId ? pageLink.replace(/(href="[^"]*)/, `$1#${headingId}`) : pageLink;
      return link + c;
    })

    // Remove /edit from docs.google.com
    .replace(/(href="https:\/\/docs\.google\.com[^"]+)\/edit[^"]*/g, (a, b: string) => {
      if (a.includes('?external') || a.includes('%26external')) return a;
      return b;
    })

    // Replace docs.google.com links, but keeps headingId
    .replace(/href="https:\/\/docs\.google\.com\/[^"]+\/d\/([^"]+)/g, (a, b: string) => {
      if (a.includes('?external') || a.includes('%26external'))
        return a.replace('?external', '').replace('%26external', '');
      return 'href="/app/page/' + b;
    })
    // Replace folder
    .replace(/href="https:\/\/drive\.google\.com\/drive\/folders\//g, 'href="/app/page/')

    // For any full URL's remaining add a target="_blank"
    // For any full URL's remaining add a OpenInNew icon
    .replace(/(href="https?:\/\/[^>]*>)([^<]*)(<\/a>)/g, (_, a: string, b: string, c: string) => {
      if (isBun && _.includes('data-skip')) return _.replace(/\s?data-skip/, '');
      const text = b.replace(/&nbsp;|\s+/g, '');
      if (!text) return _;

      return 'target="_blank" ' + a + b + openInNew + c;
    })

    // For any mailto links, add a target="_blank"
    .replace(/(href="mailto:[^>]*>[^<]*)(<\/a>)/g, (_, a: string, b: string) => {
      return 'target="_blank" ' + a + openInNew + b;
    })

    .replaceAll('/view?usp%3Ddrivesdk', '')

    // It would be cool to add a thumbnail preview when hovering
    // https://stackoverflow.com/questions/25648388/permanent-links-to-thumbnails-in-google-drive-api

    // --- URL formatting ---
    // Remove gid
    .replace(/%23gid%3D\d+/g, '')
    // Fixes links with encoded values
    // %23, %3D become # and =
    // E.g: https://drive.google.com/open?id%3D1gZmLgJLeOn5XyvIKCU-Rmb_8X1JDiU_Eck_dsFolfr8
    // E.g: https://community.atlassian.com/t5/Confluence-questions/Migrating-Data-from-confluence-to-google-Drive/qaq-p/1297000%23M198021
    .replace(/(href=")(https?:\/\/[^"\s]*)/g, (_, b: string, c: string) => {
      const result = b + decodeURIComponent(c);
      return result.replace('?usp=sharing', '');
    })

    // Numbered list is not bold
    .replace(/(<li[^>]*style=")([^>]*><span[^>]*font-weight:700;[^>]*>[^<]*<\/span><\/li>)/, '$1font-weight:700;$2')

    // --- links ---
    // Remove color inherit from links
    // http://localhost:3000/app/page/1shX1WvltMpt2GDrMyRqqJpCk9ft6U-H5/1Xv_qqN_l6kiPdN5ShEhLHbqW7Cmfxe9KLhFe3wpshCI
    // .replace(/(<a[^>]*style="[^"]*)color:inherit;?/g, '$1')

    // Replace themes.googlusercontent.com with proxy request
    // .replace(/@import url\(https:\/\/themes\.googleusercontent\.com\/fonts\/css[^)]*\)/, (a) => {
    //   const url = a.match(/url\(([^)]*)\)/)?.[1] ?? '';
    //   return a.replace(url, `/api/proxy/${encodeURIComponent(url)}`);
    // })

    // Fix Consolas font
    .replace(/(font-family:)&quot;Consolas&quot;/g, '$1SFMono-Regular,Consolas,Liberation Mono,Menlo,Courier,monospace')

    // Fix RTL for general tags
    .replace(/(<\w+\sdir="rtl"[^>]+)(text-align:left)/g, '$1')

    // Fix RTL for lists
    .replace(/(<ul)([^>]+><li\sdir="rtl"[^>]+>)/g, '$1 dir="rtl" $2')

    // Fix sup pushing content down by 3px
    // http://localhost:3000/app/page/17715VfJmL3JHx90XD8KsuZH9yz4dGP55/1CBWV4MUlYm7k5RvnskEGNsYdenNhQC-bzHKT7YPdjwM
    .replace(/<sup>/g, '<sup style="display:inline-flex;margin-top:-3px;">')

    // Remove remaining empty inline style tags
    .replace(/\s?style=""/g, '');

  // Rewrite links within this wiki from /app/page/<file.id> to /app/page/<wiki.id>/<file.id>
  if (wiki) {
    result = result.replace(/(href="\/app\/page\/)([^"]+)/g, (_, a: string, b: string) => {
      const id = b.replace(/#.+$/, '');
      const file = files?.find((f) => f.id === id);
      if (!file) return a + b;
      if (LEGACY) return `${a}${b}?p=${wiki.id}`;
      return `${a}${wiki.id}/${b}`;
    });
  }

  // Modal support
  let skipModal = true; // flag used to skip modal as it is only used in the dom once
  if (result.includes('<img')) {
    result =
      result.replace(/(<img )([^>]+>)/g, (str, a: string, b: string) => {
        if (isBun && /data-skip-modal/.test(str)) return str.replace(/data-skip-modal\s?/g, '');
        const src = (str.match(/src="([^"]+)/) || [])[1] ?? '';
        if (src.startsWith('https://cdn.loom.com/sessions/thumbnails')) return str;
        if (src === '/img/loom-play-button.svg') return str;
        skipModal = false;
        return a + modalClick(src) + ' ' + b.replace(/(style="[^"]+)/, '$1;cursor:pointer;');
      }) + (skipModal ? '' : modal);
  }

  // Code block that is formatted out of order
  // http://localhost:3000/app/page/1DgH8f84SGT5FS3A6svH_-fTdNVD1LvTb/1H54fU7qEGy3GRW8GkCmKV7PQDs40xqWNsBMeEqxoaE0
  const codeBlockPatternOutOfOrder = /<p[^>]*><span[^>]*>&#60419;[^<]*<\/span>(?:(?!&#60418;).)*&#60418;/g;
  if (codeBlockPatternOutOfOrder.test(result)) {
    result = result.replace(codeBlockPatternOutOfOrder, (a) => {
      const str = a.replace('&#60418;', '');
      const lastClosingParagraph = str.lastIndexOf('</span></p>');
      const output = str.slice(0, lastClosingParagraph) + '&#60418;' + str.slice(lastClosingParagraph, str.length);
      return output;
    });
  }

  // Code block support
  const codeBlockPattern = /<p[^>]*><span[^>]*>&#60419;[^<]*<\/span>(?:(?!&#60418;).)*&#60418;<\/span><\/p>/g;
  if (codeBlockPattern.test(html)) {
    result = result.replace(codeBlockPattern, (a) => {
      const str = a.replace('&#60419;', '').replace('&#60418;', '');

      // Reduce the spacing by 2 per level
      const baseWidth = 8;
      const baseRemove = 2;
      const output = str
        .replace(/(<span[^>]*>)((&nbsp;){8}[^>]*)<\/span>/g, (_, span: string, content: string) => {
          const count = content.match(/&nbsp;/g)?.length ?? 0;
          const level = count / baseWidth;
          const newSpaces = count - level * baseRemove;
          const spaces = '&nbsp;'.repeat(newSpaces);
          const output = span + spaces + content.replace(/&nbsp;/g, '') + '</span>';
          return output;
        })
        .replace(/(<span[^>]*)>/g, '$1 data-code-block="true">');

      return strip /* html */`
        <div class="${pageStyles.codeBlock}">${output}</div>
      `;
    });
  }

  // Add additional spacing to tabs
  // http://localhost:3000/app/page/16ysq_bBjlI_h6-yc7SxzyL5Swaj4xiU1/1fzS--OCqXEgY48-aeIDVBPfxT_qG3XAh9JZOLjmIfyQ?preview
  // Disabled as it was affecting code block
  // http://localhost:3000/app/page/158mv5BaXISZgxo1eJ-D6xPVNi-6Uodi3/1-h17ezG4orBjvaypVfEXsVP82Wl8DRGR7l8SLj-4tIM?preview
  result = result.replace(/(<span[^>]*>)((&nbsp;){8}[^>]*)<\/span>/g, (_, span: string, content: string) => {
    if (span.includes('data-code-block="true"')) return _;

    const tag = span.includes('style="')
      ? span.replace(/style="([^"]*)"/, (_, style: string) => {
          return `style="${style};margin-left:.3ch"`;
        })
      : `${span.slice(0, -1)} style="margin-left:.3ch">`;

    return `${tag}&nbsp;&nbsp;&nbsp;&nbsp;${content}</span>`;
  });

  // const pageWidth = html.match(/<body[^>]*max-width:\s*(\d+pt)[^>]*>/)?.[1];
  // const docWidth = pageWidth === '468pt' ? '688px' : pageWidth;

  return {
    content: result,
    // ...(docWidth && { docWidth }),
  };
};
