import type { DriveFile } from '~/utils/DriveAPI';
import * as fast from '~/utils/fast';
import { isAFolder } from './utils';

const collator = new Intl.Collator(undefined, { numeric: true, sensitivity: 'base' });
export const sortByNameAndFolder = (arr: DriveFile[]) =>
  [...arr].sort((a, b) => {
    if (isAFolder(a) && !isAFolder(b)) return -1;
    if (!isAFolder(a) && isAFolder(b)) return 1;
    return collator.compare(a.name, b.name);
  });

export const sortByName = (arr: DriveFile[]) => arr.sort((a, b) => collator.compare(a.name, b.name));

export const sortByNameFn = (a: DriveFile, b: DriveFile) => collator.compare(a.name, b.name);

export const sortByIndex = (files: DriveFile[] | undefined): DriveFile[] => {
  if (!files) return [];

  const filesWithoutIndex = files.filter((o) => !o?.properties?.index);
  const l = files.length;
  if (filesWithoutIndex.length === l) return files;

  const result: DriveFile[] = [];
  const filesWithIndex = files
    .filter((o) => o?.properties?.index)
    .sort((a, b) => Number(a?.properties?.index) - Number(b?.properties?.index));

  let iterator = 0;
  for (let i = 0; i < l; i++) {
    for (let j = 0; j < filesWithIndex.length; j++) {
      const file = filesWithIndex[j];
      if (!file) continue;
      const index = Number(file?.properties?.index);
      if (index <= i + iterator) {
        result.push(file);
        filesWithIndex.splice(j, 1);
        j--;
        iterator++;
      }
    }

    // No more index files to process
    if (filesWithIndex.length === 0) {
      fast.concatTo(result, filesWithoutIndex.slice(i));
      break;
    }

    // After adding indexed files, add unindexed files
    const file = filesWithoutIndex[i];
    if (file) {
      result.push(file);
    }
  }

  // Handle out of bounds indexes
  if (filesWithIndex.length > 0) {
    fast.concatTo(result, filesWithIndex);
  }

  return result;
};

export const sort = (input: DriveFile[]) => sortByIndex(sortByNameAndFolder(input));
