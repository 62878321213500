export type ExternaLink = {
  name: string;
  url: string;
};

const patterns = {
  squareBrackets: /\[([^\]]+)\]/,
  isURL: /(https?:\/\/)?(www\.)?([-\w@:%.\\+~#=]{1,256}\.[a-z]{2,}|http:\/\/localhost:\d{2,})\b([-\w@:%\\+.~#?&/=]*)/,
  markdown: /_/,
};
patterns.markdown = new RegExp(`^${patterns.squareBrackets.source}(\\(${patterns.isURL.source}\\))$`);

/**
 * Matches the format [name](url)
 */
export function isMarkdownLink(name: string): ExternaLink | undefined {
  const isURL = patterns.markdown.exec(name);
  if (!isURL?.[1]) return undefined;

  const matchedURL = isURL[2]?.slice(1, isURL[2].length - 1).trim();
  if (!matchedURL) return undefined;

  const url = /^https?:\/\/|^mailto:/.test(matchedURL) ? matchedURL : `https://${matchedURL}`;

  return {
    name: isURL[1],
    url,
  };
}
