// Ported from: https://github.com/molefrog/wouter/blob/v2.11.0/matcher.js
type Key = {
  name: string;
};

type RegexpResult = {
  regexp: RegExp;
  keys: Key[];
};

type MakeRegexpFn = (pattern: string) => RegexpResult;

type Result = { matched: boolean; params: Record<string, string> | null };
/**
 * @example
 * const { matched, params } = makeMatcher();
 */
export default function makeMatcher(
  makeRegexpFn: MakeRegexpFn = pathToRegexp
): (pattern: string, path: string) => Result {
  const cache: Record<string, RegexpResult> = {};

  const getRegexp = (pattern: string): RegexpResult => {
    const result = cache[pattern];
    if (result) {
      return result;
    }
    return cache[pattern] || (cache[pattern] = makeRegexpFn(pattern));
  };

  return (pattern: string, path: string): Result => {
    const { regexp, keys } = getRegexp(pattern || '');
    const out = regexp.exec(path);

    if (!out) return { matched: false, params: null };

    const params: Record<string, string> = {};
    keys.forEach((key, i) => {
      const value = out[i + 1];
      if (value === undefined) return;
      params[key.name] = value;
    });

    return { matched: true, params };
  };
}

export const matchPath = (pattern: string, path = window.location.pathname): Result => makeMatcher()(pattern, path);

const escapeRx = (str: string): string => str.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');

const rxForSegment = (repeat: boolean, optional: boolean, prefix: number): string => {
  let capture = repeat ? '((?:[^\\/]+?)(?:\\/(?:[^\\/]+?))*)' : '([^\\/]+?)';
  if (optional && prefix) capture = '(?:\\/' + capture + ')';
  return capture + (optional ? '?' : '');
};

const pathToRegexp: MakeRegexpFn = (pattern: string): RegexpResult => {
  const groupRx = /:(\w+)([?+*]?)/g;

  let match: RegExpExecArray | null = null;
  let lastIndex = 0;
  const keys: Key[] = [];
  let result = '';

  while ((match = groupRx.exec(pattern)) !== null) {
    const [, segment, mod] = match;

    const repeat = mod === '+' || mod === '*';
    const optional = mod === '?' || mod === '*';
    const prefix = optional && pattern[match.index - 1] === '/' ? 1 : 0;

    // eslint-disable-next-line unicorn/prefer-string-slice
    const prev = pattern.substring(lastIndex, match.index - prefix);
    if (segment) {
      keys.push({ name: segment });
    }
    lastIndex = groupRx.lastIndex;
    result += escapeRx(prev) + rxForSegment(repeat, optional, prefix);
  }

  // eslint-disable-next-line unicorn/prefer-string-slice
  result += escapeRx(pattern.substring(lastIndex));
  return { keys, regexp: new RegExp('^' + result + '(?:\\/)?$', 'i') };
};
