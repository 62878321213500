import type { DriveFile, TreeFile } from '~/utils/DriveAPI';
import { concat } from '~/utils/fast';
import { getId, isAFile, isAFolder, isAShortcut } from '~/utils/utils';
import { sort, sortByIndex } from './sort';

const getTree = (flatTree: TreeFile[], id: string): TreeFile[] => {
  const result = flatTree
    .filter((o) => o.parents?.[0] === id)
    .filter((o) => {
      // Prevent maximum call stack size exceeded caused by circular references using a shortcut
      if (isAShortcut(o)) {
        const isDuplicated = flatTree.filter((f) => getId(f) === getId(o)).length > 1;
        if (isDuplicated) return false;
      }
      return o;
    })
    .map((o) => {
      const nextTree = getTree(flatTree, getId(o));
      const children = sortByIndex(nextTree.concat(o.children ?? []));

      return {
        ...o,
        children,
      };
    });

  return result;
};

/**
 * Returns
 * - a nested tree
 * - a flat tree excluding folders as children (sidebar)
 * - a flat tree including folders as children
 */
export const createTree = (files: DriveFile[], parentId = ''): [TreeFile[], TreeFile[], TreeFile[]] => {
  // Flat tree of all folders with children
  const flatTreeWithChildren = sort(files.filter(isAFolder)).map((file) => {
    const id = getId(file);
    const children: DriveFile[] = [];
    let page: DriveFile | undefined;

    // for loop so we only have to iterate over the files once
    for (const file of files) {
      if (file.parents?.[0] === id) {
        if (file.name === 'wiki.page') {
          page = file;
          continue;
        }
        children.push(file);
      }
    }

    return {
      ...file,
      children: sort(children), // TODO: shouldn't have to sort twice
      ...(page && { page }),
      ...(isAShortcut(file) &&
        file.shortcutDetails.targetMimeType === 'application/vnd.google-apps.folder' && {
          iconLink: 'https://drive-thirdparty.googleusercontent.com/32/type/application/vnd.google-apps.folder',
        }),
    };
  });

  // Already sorted
  const flatTree = flatTreeWithChildren.map((o) => ({
    ...o,
    children: sortByIndex(o.children.filter(isAFile)),
    sortedChildren: o.children,
  }));

  // Nested tree
  const unsortedTree = concat(
    getTree(flatTree, parentId),
    files.filter((o) => isAFile(o) && o.parents?.[0] === parentId && o.name !== 'wiki.page')
  );

  // Tree is made up of top level files and unsorted tree
  const tree = sort(unsortedTree);

  return [tree, flatTree, flatTreeWithChildren];
};
