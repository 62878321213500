import { type ReactNode } from 'react';
import * as styles from './Title.css';

interface Props
  extends React.DetailedHTMLProps<React.HTMLProps<HTMLHeadingElement>, HTMLHeadingElement>,
    React.AriaAttributes {}

const joinJSXAndStrings = (array: ReactNode[]) =>
  array.reduce<ReactNode[]>((acc, curr) => {
    if (typeof acc[acc.length - 1] === 'string' && typeof curr === 'string') {
      (acc[acc.length - 1] as string) += curr;
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);

/**
 * Formats specific letter combinations in a title
 * Single letter formatting is performed in index.css
 * @returns
 */
const formatTitle = (title: string) => {
  const arr: ReactNode[] = title.split('').map((item, i, arr: ReactNode[]) => {
    // Format "fi"
    if (item === 'f' && arr[i + 1] === 'i') {
      return (
        <span
          key={`${item}-${i}`}
          style={{
            letterSpacing: 0,
          }}
        >
          {item}
        </span>
      );
    }
    // Format "LA"
    if (item === 'L' && arr[i + 1] === 'A') {
      return (
        <span
          key={`${item}-${i}`}
          style={{
            letterSpacing: 0.5,
          }}
        >
          {item}
        </span>
      );
    }
    // Format "rt"
    if (item === 'r' && arr[i + 1] === 't') {
      return (
        <span
          key={`${item}-${i}`}
          style={{
            letterSpacing: 0,
          }}
        >
          {item}
        </span>
      );
    }
    return item;
  });

  return joinJSXAndStrings(arr);
};

const shouldFormat = (children: ReactNode): children is string => {
  if (typeof children !== 'string' || !children) return false;
  const result = children.includes('fi') || children.includes('LA') || children.includes('rt');
  return result;
};

const Title: React.FC<Props> = (props) => {
  const { children, className, ...rest } = props;

  // Format headings
  const result = shouldFormat(children) ? formatTitle(children) : children;

  return (
    <h1 className={[className ?? styles.title].join(' ')} {...rest}>
      {result}
    </h1>
  );
};

export default Title;
