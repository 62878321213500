import { useEffect, useLayoutEffect, useMemo, useReducer } from 'react';
import { createPortal } from 'react-dom';
import { ls, safeParse } from '~/utils/utils';
import { checkbox } from './CheckboxMacro.css';

export const CheckboxMacro = ({ content, query, id }: { content: string; query: string; id: string }) => {
  const [updated, forceUpdate] = useReducer((x: number) => x + 1, 0);
  const checked = useMemo(() => ls.get<Record<string, boolean>>(`checkbox-${id}`), [id]);

  useLayoutEffect(() => {
    if (!content) return;
    const nodes = Array.from(document.querySelectorAll(query));

    // Add labels for checkboxes
    nodes.forEach((node, i) => {
      const span = node.nextElementSibling;
      if (span instanceof HTMLElement && span.tagName === 'SPAN') {
        const label = document.createElement('label');
        label.htmlFor = i.toString();
        label.append(span);
        node.after(label);
      }
      if (node instanceof HTMLElement) {
        const props = node.dataset.props ? safeParse<{ checked?: boolean }>(node.dataset.props) : undefined;
        if (props?.checked) {
          const li = node.closest('li');
          if (li instanceof HTMLElement) {
            li.style.textDecoration = 'line-through';
            li.style.color = '#585959';
          }
        }
      }

      const hasChecked = checked ? Object.keys(checked).length > 0 : false;
      if (!hasChecked) return;

      // Apply user saved checkboxes
      const k = `${i}`;
      if (checked?.[k]) {
        const li = node.closest('li');
        if (li instanceof HTMLElement) {
          li.style.textDecoration = 'line-through';
          li.style.color = '#585959';
        }
      } else if (!checked?.[i]) {
        const li = node.closest('li');
        if (li instanceof HTMLElement) {
          li.style.textDecoration = '';
          li.style.color = '';
        }
        // wait for the portal to render
        queueMicrotask(() => {
          const input = li?.querySelector('input');
          if (input instanceof HTMLElement) {
            input.checked = false;
          }
        });
      }
    });
  }, [content, query, checked, updated]);

  const nodes = Array.from(document.querySelectorAll(query));

  // test these scenarios with `preview` after updating:
  // - navigating to a different page
  // - updating a document checkboxes
  useEffect(() => {
    const components = document.querySelectorAll('[data-react="Checkbox"]');
    if (
      (nodes.length > 0 && document.querySelectorAll('[data-react="Checkbox"] input[type="checkbox"]').length === 0) ||
      (nodes.length === 0 && components.length > 0)
    ) {
      forceUpdate();
    }
  });

  if (!nodes.length) return null;
  return nodes.map((node, i) => {
    // get props from data-props
    const data = 'dataset' in node && typeof node.dataset === 'object' ? { ...node.dataset } : {};
    const props =
      data && 'props' in data && typeof data.props === 'string'
        ? safeParse<{ checked?: boolean }>(data.props)
        : undefined;

    return createPortal(
      <input
        className={checkbox}
        id={`${i}`}
        type="checkbox"
        defaultChecked={checked?.[i] ?? props?.checked}
        onChange={() => {
          const li = node.closest('li');
          if (li instanceof HTMLElement) {
            const hasLineThrough = li.style.textDecoration === 'line-through';
            li.style.textDecoration = hasLineThrough ? '' : 'line-through';
            li.style.color = hasLineThrough ? '' : '#585959';
          }
          const checked = nodes.map((o) => o.closest('li')).map((li) => li?.style.textDecoration === 'line-through');

          const value = Object.fromEntries(nodes.map((_, i) => [i, checked[i] ?? false]));
          ls.set(`checkbox-${id}`, value);
        }}
      />,
      node
    );
  });
};
