import { Avatar, AvatarGroup } from '@mui/material';
import { type DriveFile } from '~/utils/DriveAPI';
import { DEV } from '~/utils/constants';
import { getTextFromHTML, readingTime, resizePhoto, toLastModifiedTime, toTitleCase } from '~/utils/utils';
import * as styles from './LastModified.css';

const profileSize = 36 * window.devicePixelRatio;

const LastModified = ({ content, file }: { content?: string | undefined; file: DriveFile }) => {
  const owner = file.owners?.[0];
  const lastModifyingUser = file.lastModifyingUser;
  const text = content ? getTextFromHTML(content) : '';
  const showLastUpdated = lastModifyingUser && lastModifyingUser.displayName !== owner?.displayName;

  return (
    <div className={styles.container}>
      <AvatarGroup className={styles.avatarGroup}>
        {owner && (
          <Avatar
            className={styles.createdAvatar}
            {...(DEV && {
              imgProps: {
                referrerPolicy: 'no-referrer',
              },
            })}
            alt={owner.displayName}
            src={resizePhoto(owner.photoLink, profileSize)}
          >
            {owner.displayName[0]}
          </Avatar>
        )}
        {lastModifyingUser && owner?.photoLink !== lastModifyingUser.photoLink && (
          <Avatar
            className={styles.lastModifiedAvatar}
            {...(DEV && {
              imgProps: {
                referrerPolicy: 'no-referrer',
              },
            })}
            alt={lastModifyingUser.displayName}
            src={resizePhoto(lastModifyingUser.photoLink, profileSize)}
          >
            {lastModifyingUser.displayName[0]}
          </Avatar>
        )}
      </AvatarGroup>

      <div className={styles.textContainer}>
        {owner && <div>Created by {toTitleCase(owner.displayName)}</div>}
        <div>
          Last updated: {toLastModifiedTime(file.modifiedTime)}
          {showLastUpdated && ` by ${toTitleCase(lastModifyingUser.displayName)}`}
          {text.length > 0 ? <span> • {readingTime(text)}</span> : ''}
        </div>
      </div>
    </div>
  );
};
export default LastModified;
