import { type DriveFile } from '~/utils/DriveAPI';
import { concat } from '~/utils/fast';
import { getId } from '~/utils/utils';

/**
 * Get all ancestors of a file
 * @param files list of files
 * @param parentId parent id of the file
 */
const getAllAncestors = (files: DriveFile[], parentId: string): DriveFile[] => {
  const parent = files.find((file) => getId(file) === parentId);
  if (!parent) return [];
  if (!parent.parents) return [parent];
  return concat([parent], getAllAncestors(files, parent.parents[0]));
};

export default getAllAncestors;
