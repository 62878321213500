import createSvgIcon from '@mui/material/utils/createSvgIcon';

export const Search = createSvgIcon(
  <>
    <path d="M20.49 19l-5.73-5.73C15.53 12.2 16 10.91 16 9.5A6.5 6.5 0 1 0 9.5 16c1.41 0 2.7-.47 3.77-1.24L19 20.49 20.49 19zM5 9.5C5 7.01 7.01 5 9.5 5S14 7.01 14 9.5 11.99 14 9.5 14 5 11.99 5 9.5z" />
  </>,
  'Search'
);

export const Ctrl = createSvgIcon(
  <>
    <path
      d="M4.505 4.496h2M5.505 5.496v5M8.216 4.496l.055 5.993M10 7.5c.333.333.5.667.5 1v2M12.326 4.5v5.996M8.384 4.496c1.674 0 2.116 0 2.116 1.5s-.442 1.5-2.116 1.5M3.205 9.303c-.09.448-.277 1.21-1.241 1.203C1 10.5.5 9.513.5 8V7c0-1.57.5-2.5 1.464-2.494.964.006 1.134.598 1.24 1.342M12.553 10.5h1.953"
      strokeWidth="1.2"
      stroke="currentColor"
      fill="none"
      strokeLinecap="square"
    />
  </>,
  'Ctrl'
);

export const Document = createSvgIcon(
  <>
    <rect width="257" height="257" rx="30" fill="white" fillOpacity="0.32" />
    <rect x="58" y="57" width="142" height="28" fill="currentColor" />
    <rect x="58" y="114" width="142" height="28" fill="currentColor" />
    <rect x="58" y="171" width="99" height="28" fill="currentColor" />
  </>,
  'Document'
);

export const Shortcut = createSvgIcon(
  <>
    <path
      d="M128.5 257C199.469 257 257 199.469 257 128.5C257 57.5314 199.469 0 128.5 0C57.5314 0 0 57.5314 0 128.5C0 199.469 57.5314 257 128.5 257Z"
      fill="white"
      fillOpacity="1"
    />
    <path
      d="M160.625 48.1875H96.375V64.25H130.909C118.022 73.2277 108.05 85.7871 102.227 100.374C96.4039 114.961 94.9859 130.935 98.1483 146.32C101.311 161.704 108.915 175.824 120.019 186.931C131.124 198.039 145.241 205.646 160.625 208.812V192.75C148.215 189.599 137.029 182.812 128.504 173.259C119.978 163.707 114.5 151.825 112.774 139.138C111.048 126.451 113.152 113.538 118.816 102.055C124.48 90.5716 133.445 81.0428 144.562 74.6906V112.438H160.625V48.1875Z"
      fill="#5F6368"
    />
  </>,
  'Shortcut'
);

export const Trash = createSvgIcon(
  <>
    <path d="M0 0h24v24H0z" fill="none" />
    <path d="M15 4V3H9v1H4v2h1v13c0 1.1.9 2 2 2h10c1.1 0 2-.9 2-2V6h1V4h-5zm2 15H7V6h10v13z" />
    <path d="M9 8h2v9H9zm4 0h2v9h-2z" />
  </>,
  'Trash'
);

export const DriveMove = createSvgIcon(
  <>
    <path fill="none" d="M0 0h24v24H0V0z" />
    <path d="M20 6h-8l-2-2H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm0 12H4V8h16v10zm-8.01-9l-1.41 1.41L12.16 12H8v2h4.16l-1.59 1.59L11.99 17 16 13.01 11.99 9z" />
  </>,
  'DriveMove'
);

export const DriveCopy = createSvgIcon(
  <path d="M19 19H8q-.825 0-1.412-.587Q6 17.825 6 17V3q0-.825.588-1.413Q7.175 1 8 1h7l6 6v10q0 .825-.587 1.413Q19.825 19 19 19ZM14 8V3H8v14h11V8ZM4 23q-.825 0-1.412-.587Q2 21.825 2 21V7h2v14h11v2ZM8 3v5-5 14V3Z" />,
  'DriveCopy'
);
