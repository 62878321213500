import type { DriveFile, TreeFile } from '~/utils/DriveAPI';
import { getId, getParentFolders, isAShortcut } from '~/utils/utils';
import { createTree } from './createTree';
import { sort } from './sort';

/**
 * Go up the tree until we find a parent with a next child
 */
const getNextChild = (
  files: DriveFile[],
  file: DriveFile,
  wiki: DriveFile,
  flatTree: TreeFile[]
): DriveFile | undefined => {
  if (!file.parents) return undefined;
  const parents = getParentFolders(files, file.parents[0]);
  const parent = files.find((o) => getId(o) === file.parents?.[0]);
  const currentFiles = sort(files.filter((o) => o.parents?.[0] === parent?.parents?.[0]));
  const fileIndex = currentFiles.findIndex((o) => o.id === parent?.id);
  const nextNode = currentFiles[fileIndex + 1];

  if (nextNode) return nextNode;

  // If there is no nextNode and no parent
  // This is a top level file that is the last item in the wiki
  if (!parent) return wiki;

  // Walk up the tree until we find a parent with a next child
  for (const parent of parents) {
    const children = flatTree.find((o) => o.id === parent.id)?.children;
    if (children?.some((o) => o.id === file.id)) {
      const nextChild = getNextChild(files, parent, wiki, flatTree);
      if (nextChild) return nextChild;
    }
  }

  return undefined;
};

type NextProps = {
  file: DriveFile | undefined;
  files: DriveFile[];
  wiki: DriveFile | undefined;
};

export const getNext = ({ file, files: f, wiki }: NextProps): DriveFile | undefined => {
  const files = sort(f.filter((o) => o.name !== 'wiki.page'));
  const isWiki = file?.id === wiki?.id;
  const currentFiles = sort(files.filter((o) => o.parents?.[0] === (isWiki ? wiki?.id : file?.parents?.[0])));
  const fileIndex = currentFiles.findIndex((o) => o.id === file?.id);
  const nextNode = currentFiles.at(fileIndex + 1);
  const flatTree = createTree(files, wiki?.id)?.[2];
  const fileWithChildren = flatTree.find((o) => o.id === file?.id);
  const children = fileWithChildren?.children;
  const sortedChildren = children ? sort(children) : [];

  if (!nextNode && files.length <= 1) return undefined;

  const nextChild = sortedChildren.at(0);
  const duplicate = isAShortcut(file) ? files.some((o) => o.id === getId(file)) : false;
  if (nextChild && !duplicate) return nextChild;

  if (!nextNode) {
    if (!file || !wiki) return undefined;
    const nextChild = getNextChild(files, file, wiki, flatTree);
    if (nextChild) return nextChild;
    return wiki;
  }

  return nextNode;
};
