import { useRef } from 'react';
import { useSWRConfig } from 'swr';
import { Link, useLocation } from 'wouter';
import * as pageStyles from '~/components/Page/Page.css';
import { type DriveFile } from '~/utils/DriveAPI';
import { LEGACY, disableFocusRequest } from '~/utils/constants';
import { getNext } from '~/utils/getNext';
import { getPrev } from '~/utils/getPrev';
import { useIsVisible, useKeyPress } from '~/utils/hooks';
import { isMarkdownLink } from '~/utils/isMarkdownLink';
import { isModalEvent, prefetch } from '~/utils/utils';
import * as styles from './PrevNext.css';

const PrevNext = ({ file, files, wiki }: { file: DriveFile; files: DriveFile[]; wiki: DriveFile }) => {
  const { cache } = useSWRConfig();

  // Prev
  const prev = getPrev({
    file,
    files,
    wiki,
  });

  // Next
  const next = getNext({
    file,
    files,
    wiki,
  });

  // Prefetch prev & next
  const prevLinkRef = useRef<HTMLAnchorElement>(null);
  const nextLinkRef = useRef<HTMLAnchorElement>(null);
  useIsVisible(prevLinkRef, disableFocusRequest ? () => {} : () => prev && prefetch(prev, cache), [prev?.id]);
  useIsVisible(nextLinkRef, disableFocusRequest ? () => {} : () => next && prefetch(next, cache), [next?.id]);

  const legacyPrevTo = prev ? (LEGACY ? `/app/page/${prev.id}?p=${wiki.id}` : `/app/page/${prev.id}`) : '';
  const legacyNextTo = next ? (LEGACY ? `/app/page/${next.id}?p=${wiki.id}` : `/app/page/${next.id}`) : '';

  // Keyboard support for left and right
  const navigate = useLocation()[1];
  useKeyPress(['ArrowLeft', 'ArrowRight'], (e: KeyboardEvent) => {
    if (isModalEvent(e)) return;
    if (e.metaKey || e.ctrlKey) return;
    e.preventDefault();
    if (!wiki.id) return;

    if (e.key === 'ArrowLeft' && prev) {
      if (LEGACY) {
        window.legacyHistory?.push(legacyPrevTo);
      } else {
        navigate(`/app/page/${wiki.id}/${prev.id}`);
      }
    } else if (e.key === 'ArrowRight' && next) {
      if (LEGACY) {
        window.legacyHistory?.push(legacyNextTo);
      } else {
        navigate(`/app/page/${wiki.id}/${next.id}`);
      }
    }
  });

  const prevExternalLink = prev?.name ? isMarkdownLink(prev.name) : undefined;
  const nextExternalLink = next?.name ? isMarkdownLink(next.name) : undefined;
  const prevName = prevExternalLink ? prevExternalLink.name : prev?.name;
  const nextName = nextExternalLink ? nextExternalLink.name : next?.name;

  return (
    <div className={styles.container}>
      {wiki && prev && (
        <Link
          className={pageStyles.bottomNavigationButton}
          ref={prevLinkRef}
          onMouseDown={() => prefetch(prev, cache)}
          to={LEGACY ? legacyPrevTo : `/app/page/${wiki.id}/${prev.id}`}
          {...(LEGACY && {
            onClick: () => {
              window.legacyHistory?.push(legacyPrevTo);
            },
          })}
        >
          <div className={pageStyles.paginationSubLabel}>« Previous</div>
          <div className={pageStyles.paginationLabel}>{prevName}</div>
        </Link>
      )}
      {wiki && next && (
        <Link
          className={pageStyles.bottomNavigationButton}
          ref={nextLinkRef}
          onMouseDown={() => prefetch(next, cache)}
          to={LEGACY ? legacyNextTo : `/app/page/${wiki.id}/${next.id}`}
          {...(LEGACY && {
            onClick: () => {
              window.legacyHistory?.push(legacyNextTo);
            },
          })}
          style={{
            textAlign: 'right',
          }}
        >
          <div className={pageStyles.paginationSubLabel}>Next »</div>
          <div className={pageStyles.paginationLabel}>{nextName}</div>
        </Link>
      )}
    </div>
  );
};
export default PrevNext;
