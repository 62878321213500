import Menu, { type MenuProps } from '@mui/material/Menu';
import MenuItem, { type MenuItemProps } from '@mui/material/MenuItem';
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
  type ElementType,
  type FocusEvent,
  type HTMLAttributes,
  type KeyboardEvent,
  type MouseEvent,
  type ReactNode,
  type RefAttributes,
} from 'react';

export type NestedMenuItemProps = Omit<MenuItemProps, 'button'> & {
  parentMenuOpen: boolean;
  component?: ElementType;
  label?: string;
  leftIcon?: ReactNode;
  children?: ReactNode;
  className?: string;
  tabIndex?: number;
  disabled?: boolean;
  ContainerProps?: HTMLAttributes<HTMLElement> & RefAttributes<HTMLElement | null>;
  MenuProps?: Partial<Omit<MenuProps, 'children'>>;
  button?: true | undefined;
};

const NestedMenuItem = forwardRef<HTMLDivElement | null, NestedMenuItemProps>((props, ref) => {
  const {
    parentMenuOpen,
    label,
    leftIcon = null,
    children,
    className,
    tabIndex: tabIndexProp,
    ContainerProps: ContainerPropsProp = {},
    onClick,
    onMouseDown,
    MenuProps,
  } = props;

  const { ref: containerRefProp, ...ContainerProps } = ContainerPropsProp;

  const menuItemRef = useRef<HTMLDivElement | null>(null);
  useImperativeHandle<HTMLDivElement | null, HTMLDivElement | null>(ref, () => menuItemRef.current);

  const containerRef = useRef<HTMLLIElement | null>(null);
  useImperativeHandle<HTMLLIElement | null, HTMLLIElement | null>(
    containerRefProp as React.ForwardedRef<HTMLLIElement | null>,
    () => containerRef.current
  );

  const menuContainerRef = useRef<HTMLDivElement | null>(null);

  const [isSubMenuOpen, setIsSubMenuOpen] = useState(false);

  const handleMouseEnter = (e: MouseEvent<HTMLElement>) => {
    setIsSubMenuOpen(true);

    if (ContainerProps.onMouseEnter) {
      ContainerProps.onMouseEnter(e);
    }
  };
  const handleMouseLeave = (e: MouseEvent<HTMLElement>) => {
    setIsSubMenuOpen(false);

    if (ContainerProps.onMouseLeave) {
      ContainerProps.onMouseLeave(e);
    }
  };

  // Check if any immediate children are active
  const isSubmenuFocused = () => {
    const active = containerRef.current?.ownerDocument.activeElement ?? null;

    if (!menuContainerRef.current) {
      return false;
    }

    for (const child of menuContainerRef.current.children) {
      if (child === active) {
        return true;
      }
    }

    return false;
  };

  const handleFocus = (e: FocusEvent<HTMLElement>) => {
    if (e.target === containerRef.current) {
      setIsSubMenuOpen(true);
    }

    if (ContainerProps.onFocus) {
      ContainerProps.onFocus(e);
    }
  };

  const handleBlur = (e: FocusEvent<HTMLElement>) => {
    // If relatedTarget is not a child of the submenu, close the submenu
    if (e.currentTarget === e.relatedTarget) {
      e.currentTarget.focus();
    } else {
      if (
        !menuContainerRef.current ||
        !e.currentTarget ||
        !e.relatedTarget ||
        !menuContainerRef.current.contains(e.relatedTarget as Node)
      ) {
        setIsSubMenuOpen(false);
      }
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      setIsSubMenuOpen(false);
      return;
    }

    const focused = isSubmenuFocused();

    if (focused) {
      e.stopPropagation();
    }

    const active = containerRef.current?.ownerDocument.activeElement;

    if (e.key === 'ArrowLeft' && focused) {
      containerRef.current?.focus();
    }

    if (e.key === 'ArrowRight' && e.target === containerRef.current && e.target === active) {
      const firstChild = menuContainerRef.current?.children[0] as HTMLDivElement;
      firstChild?.focus();
    }

    if (e.key === 'Enter' && !focused) {
      const child = containerRef.current?.firstElementChild as HTMLElement;
      child?.click();
    }
  };

  const open = isSubMenuOpen && parentMenuOpen;

  // Root element must have a `tabIndex` attribute for keyboard navigation
  let tabIndex: number | undefined;
  if (!props.disabled) {
    tabIndex = tabIndexProp !== undefined ? tabIndexProp : -1;
  }

  return (
    <MenuItem
      ref={containerRef}
      onFocus={handleFocus}
      onBlur={handleBlur}
      tabIndex={tabIndex ?? 0}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onKeyDown={handleKeyDown}
      {...(className && { className })}
      style={{
        padding: 0,
        display: 'block',
      }}
    >
      <div
        ref={menuItemRef}
        style={{
          display: 'flex',
          gap: '0.625rem',
          padding: '0.625rem 1.25rem .625rem 1rem',
          width: '100%',
        }}
        // onClick and onMouseDown are passed to the div element to allow
        // child menuItems to be clickable
        {...(onClick && ({ onClick } as { onClick: (e: MouseEvent<HTMLElement>) => void }))}
        {...(onMouseDown && ({ onMouseDown } as { onMouseDown: (e: MouseEvent<HTMLElement>) => void }))}
      >
        {leftIcon}
        {label}
      </div>
      <Menu
        // Set pointer events to 'none' to prevent the invisible Popover div
        // from capturing events for clicks and hovers
        style={{ pointerEvents: 'none' }}
        anchorEl={() => menuItemRef.current as Element}
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        transformOrigin={{
          horizontal: 'left',
          vertical: 'top',
        }}
        open={open}
        autoFocus={false}
        disableAutoFocus
        disableEnforceFocus
        onClose={() => {
          setIsSubMenuOpen(false);
        }}
        {...MenuProps}
      >
        <div ref={menuContainerRef} style={{ pointerEvents: 'auto' }}>
          {children}
        </div>
      </Menu>
    </MenuItem>
  );
});

NestedMenuItem.displayName = 'NestedMenuItem';
export { NestedMenuItem };
