import { apiKey, type mimeTypes } from '~/utils/constants';
import { type DriveFile } from './DriveAPI';

type DocsView = {
  setParent: (parent: string) => DocsView;
  setOwnedByMe: (ownedByMe: boolean) => DocsView;
  setMimeTypes: (mimeTypes: string) => DocsView;
  setIncludeFolders: (includeFolders: boolean) => DocsView;
  setSelectFolderEnabled: (selectFolderEnabled: boolean) => DocsView;
  setLabel: (label: string) => DocsView;
  setMode: (mode: 'grid' | 'list') => DocsView;
  setEnableDrives: (enableDrives: boolean) => DocsView;
};

declare global {
  const google: Google;
  interface Window {
    google: Google;
  }
}

export type Google = {
  picker: {
    Action: {
      CANCEL: 'cancel';
      PICKED: 'picked';
      ERROR: 'error';
      LOADED: 'loaded';
    };
    PickerBuilder: {
      new (): PickerBuilder;
    };
    DocsView: {
      new (view: string): DocsView;
    };
    DocsUploadView: {
      new (): DocsView;
    };
    DocsViewMode: {
      GRID: 'grid';
      LIST: 'list';
    };
    ViewId: {
      DOCS: string;
      DOCS_IMAGES: string;
      DOCS_IMAGES_AND_VIDEOS: string;
      DOCS_VIDEOS: string;
      DOCUMENTS: string;
      DRAWINGS: string;
      FOLDERS: string;
      FORMS: string;
      IMAGE_SEARCH: string;
      IMAGES: string;
      MAPS: string;
      PDFS: string;
      PHOTO_ALBUMS: string;
      PHOTOS: string;
      PRESENTATIONS: string;
      RECENTLY_PICKED: string;
      SPREADSHEETS: string;
      VIDEO_SEARCH: string;
      VIDEOS: string;
    };
  };
};

export type PickerFile = {
  id: string;
  url: string;
  name: string;
  embedUrl: string;
  iconUrl: string;
  type: 'document' | 'folder' | 'shortcut';
  mimeType: (typeof mimeTypes)[keyof typeof mimeTypes];
  sizeBytes: number;
  parentId: string;
  description: string;
  isShared: boolean;
  lastEditedUtc: number;
  serviceId: 'doc';
};

type ValueOf<T> = T[keyof T];

// https://developers.google.com/drive/picker/reference#picker
export type Picker = {
  isVisible: () => boolean;
  setVisible: (visible: boolean) => void;
  setCallback: (
    callback: (data: { action: ValueOf<Google['picker']['Action']>; docs: PickerFile[] }) => void
  ) => Picker;
  dispose: () => void;
};

// https://developers.google.com/drive/picker/reference#picker-builder
export type PickerBuilder = {
  addView: (view: unknown) => PickerBuilder;
  setOAuthToken: (token: string) => PickerBuilder;
  setDeveloperKey: (key: string) => PickerBuilder;
  setCallback: (callback: Parameters<Picker['setCallback']>[0]) => PickerBuilder;
  setOrigin: (origin: string) => PickerBuilder;
  setAppId: (appId: string) => PickerBuilder;
  setSelectableMimeTypes: (mimeTypes: string[]) => PickerBuilder;
  setSize: (width: number, height: number) => PickerBuilder;
  setLocale: (locale: string) => PickerBuilder;
  setTitle: (title: string) => PickerBuilder;
  setMaxItems: (maxItems: number) => PickerBuilder;
  build: () => Picker;
};

type PickerType = 'file' | 'folder' | 'logo';

type DrivePickerProps = {
  loaded: boolean;
  picker: Record<PickerType, Picker | undefined>;
};

export class DrivePicker {
  static props: DrivePickerProps = {
    loaded: false,
    picker: {
      file: undefined,
      folder: undefined,
      logo: undefined,
    },
  };

  static async load() {
    if (this.props.loaded) return Promise.resolve();
    await new Promise<void>((resolve) => {
      gapi.load('picker', () => resolve());
    });
    this.props.loaded = true;
  }

  static generatePicker(options: {
    folderId?: string | undefined;
    wiki?: DriveFile | undefined;
    onAction?: Parameters<PickerBuilder['setCallback']>[0];
    type: PickerType;
  }) {
    const { folderId, onAction, type, wiki } = options;
    if (!gapi.auth) {
      throw new Error('gapi.auth is not defined');
    }

    const token = gapi.auth?.getToken();

    if (!token) {
      throw new Error('No token');
    }

    const pickerBuilder = new window.google.picker.PickerBuilder()
      .setOAuthToken(token.access_token)
      .setDeveloperKey(apiKey)
      .setCallback(
        onAction ??
          (() => {
            throw new Error('Picker callback not set');
          })
      )
      .setOrigin(window.location.origin)
      .setSize(1051, 650);

    if (type === 'file') {
      const driveView = new google.picker.DocsView(google.picker.ViewId.DOCS)
        .setLabel('My Drive')
        .setParent('root')
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true);

      const recentView = new google.picker.DocsView(google.picker.ViewId.RECENTLY_PICKED)
        .setParent('root')
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true);

      const sharedWithMe = new google.picker.DocsView(google.picker.ViewId.DOCS)
        .setParent('sharedWithMe')
        .setLabel('Shared with me')
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true);

      const sharedDrives = new google.picker.DocsView(google.picker.ViewId.DOCS)
        .setIncludeFolders(true)
        .setLabel('Shared Drives')
        .setSelectFolderEnabled(true)
        .setEnableDrives(true);

      const parentFolder = folderId
        ? new google.picker.DocsView(google.picker.ViewId.DOCS)
            .setParent(folderId)
            .setLabel('YNAW')
            .setIncludeFolders(true)
            .setSelectFolderEnabled(true)
        : undefined;

      const wikiFolder = wiki?.id
        ? new google.picker.DocsView(google.picker.ViewId.DOCS)
            .setParent(wiki.id)
            .setLabel(wiki.name)
            .setIncludeFolders(true)
            .setSelectFolderEnabled(true)
        : undefined;

      if (wikiFolder) {
        pickerBuilder.addView(wikiFolder);
      }
      if (parentFolder) {
        pickerBuilder.addView(parentFolder);
      }
      pickerBuilder.addView(driveView);
      pickerBuilder.addView(recentView);
      pickerBuilder.addView(sharedWithMe);
      pickerBuilder.addView(sharedDrives);
    } else if (type === 'folder') {
      const driveView = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
        .setLabel('My Drive')
        .setParent('root')
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true);

      const sharedWithMe = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
        .setParent('sharedWithMe')
        .setLabel('Shared with me')
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true);

      const sharedDrives = new google.picker.DocsView(google.picker.ViewId.FOLDERS)
        .setIncludeFolders(true)
        .setLabel('Shared Drives')
        .setSelectFolderEnabled(true)
        .setEnableDrives(true);

      const parentFolder = folderId
        ? new google.picker.DocsView(google.picker.ViewId.FOLDERS)
            .setParent(folderId)
            .setLabel('YNAW')
            .setIncludeFolders(true)
            .setSelectFolderEnabled(true)
        : undefined;

      const wikiFolder = wiki?.id
        ? new google.picker.DocsView(google.picker.ViewId.FOLDERS)
            .setParent(wiki.id)
            .setLabel(wiki.name)
            .setIncludeFolders(true)
            .setSelectFolderEnabled(true)
        : undefined;

      if (wikiFolder) {
        pickerBuilder.addView(wikiFolder);
      }
      if (parentFolder) {
        pickerBuilder.addView(parentFolder);
      }
      pickerBuilder.addView(driveView);
      pickerBuilder.addView(sharedWithMe);
      pickerBuilder.addView(sharedDrives);
    } else if (type === 'logo') {
      const view = new google.picker.DocsView(google.picker.ViewId.DOCS_IMAGES).setLabel('My Drive').setParent('root');
      const uploadView = new google.picker.DocsUploadView();
      pickerBuilder.addView(view).addView(uploadView);
    }

    this.props.picker[type] = pickerBuilder.build();
    this.props.picker[type]?.setCallback((data) => {
      onAction?.(data);
      if (data.action === google.picker.Action.CANCEL) {
        this.dispose(type);
      }
    });

    return this.props.picker[type];
  }

  static dispose(type: PickerType) {
    this.props.picker[type]?.dispose();
    this.props.picker[type] = undefined;
  }

  static open(type: PickerType) {
    this.props.picker[type]?.setVisible(true);
    document.querySelector('div.picker-dialog-bg')?.addEventListener('click', () => this.dispose(type), { once: true });
  }
}
